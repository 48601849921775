/**
 * @ComponentFor CmsPageViewModel
 */
import React from 'react';
import { EpiProperty } from '@avensia/scope-episerver';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import CmsPageViewModelType from './CmsPageViewModel.type';

type PropType = CmsPageViewModelType;

export default function CmsPage(props: PropType) {
  return (
    <Main appearance={PageAppearance.Narrow}>
      <Section>
        <EpiProperty for={props.page.mainContent} />
      </Section>
    </Main>
  );
}
